
import { createSlice } from '@reduxjs/toolkit';

const initialState = {

};

const CommonSlice = createSlice({
    name:'Common',
    initialState,
    reducers:{
       
    }
})

// Action creators are generated for each case reducer function
export const {
    
  } = CommonSlice.actions;
  
  export default CommonSlice;