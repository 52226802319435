import logo from './logo.svg';
import './App.css';
import ChartCardList from './chart-card-list';
import 'bootstrap/dist/css/bootstrap.min.css';

function App() {
  return (
    <ChartCardList />
  );
}

export default App;
