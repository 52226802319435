import { apiClient } from ".";

const getGraphData = (query: any, graph_type: any, deviceId: any): Promise<any> => {
  return apiClient.get(`/devices/${deviceId}/${graph_type}`, { params: query });
};

const getDevices = (): Promise<any> => {
  return apiClient.get("/devices");
};

export const GraphService = {
  getGraphData,
  getDevices
};
