import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import {environment} from '../environments/environment';
import { apiClient } from '../store/services';

const requestHeaders = (req: AxiosRequestConfig): object => {
  const headers:any = {
    "X-Auth-Token": environment.apiToken
  };  
  req.headers = headers;
  return req;
};

const httpInterceptor = (dispatch: any): any => {
  apiClient.interceptors.request.use(
    (req: any): any => {
      return requestHeaders(req);
    },
    (err: AxiosError) => {
      return Promise.reject(err);
    }
  );
  // interceptor used for User Management service - POST method
  apiClient.interceptors.response.use(
    (res: AxiosResponse) => {
      if (res.status === 201 || res.status === 200) {
      }
      return res;
    },
    (err: AxiosError) => {
      if (err.response) {
        const { data, status } = err.response;
        if (status === 401) {
        }
        return Promise.reject(err);
      }
      return Promise.reject(err);
    }
  );

 
};
export default httpInterceptor;
