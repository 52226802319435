import { useEffect, useState } from "react";
import { Card } from "react-bootstrap";
import { GraphService } from "../store/services";

export default function ChartCard(props: any) {
    const { dates, graphDetail, deviceId } = props;
    const [chartData, setChartData] = useState("");
    const viewPortWidth = Math.floor(window.innerWidth) - 48;

    useEffect(() => {
        if (Object.keys(dates).length) {
            const fromDateTimestamp = dates.from_date;
            const toDateTimestamp = dates.to_date;
            GraphService.getGraphData({
                width: viewPortWidth,
                // height:100,
                from: fromDateTimestamp,
                to: toDateTimestamp,
                output: 'base64',
                legend: 'no'
            }, graphDetail.key, deviceId)
                .then(result => {
                    setChartData(result.data.image?.image)
                })
                .catch(error => console.log('error', error));
        }

    }, [dates, deviceId])

    return (
        <div>
            <p>{graphDetail.title}</p>
            {chartData ?
                <Card style={{ width: viewPortWidth }} bg="light">
                    {/* {chartData && <img src={chartData} alt="Error..." />} */}
                    <img src={`data:image/png;base64, ${chartData}`} alt="Error..." />
                </Card>
                :
                <p className="ps-4 m-0 text-muted">No Data Found..!!</p>
            }
        </div>
    )
}