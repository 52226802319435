import { useEffect, useState } from "react"
import { Button, ButtonGroup, Dropdown, DropdownButton, Form, Tab, Tabs } from "react-bootstrap";

const absoluteButtons = [
    { id: 0, text: "1min", mins: 1 },
    { id: 1, text: "3min", mins: 3 },
    { id: 2, text: "5min", mins: 5 },
    { id: 3, text: "15min", mins: 15 },
    { id: 4, text: "30min", mins: 30 },
    { id: 5, text: "45min", mins: 45 },
    { id: 6, text: "1hour", mins: 60 },
    { id: 7, text: "1day", mins: 24 * 60 },
    { id: 8, text: "15day", mins: 15 * 24 * 60 },
    { id: 9, text: "1week", mins: 7 * 24 * 60 },
    { id: 10, text: "1month" },
    { id: 11, text: "3months" },
    { id: 12, text: "6months" },
    { id: 13, text: "1year" },
]

function formatDateToISOString(date: any) {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-based, so add 1
    const day = String(date.getDate()).padStart(2, '0');
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');

    return `${year}-${month}-${day}T${hours}:${minutes}`;
}

const yesterday = new Date();
yesterday.setDate(yesterday.getDate() - 1);
const today = new Date();

export default function DateRangePicker(props: any) {
    const { setDates } = props;

    const [fromDate, setFromDate] = useState(formatDateToISOString(yesterday));
    const [toDate, setToDate] = useState(formatDateToISOString(today));
    const [errors, setErrors] = useState<any>({});
    const [selectedAbsoluteButton, setSelectedAbsoluteButton] = useState(3);

    useEffect(() => {
        // set Default time 15 mins time interval
        setDates({ from_date: Math.floor(new Date().getTime() / 1000) - 15 * 60, to_date: Math.floor(new Date().getTime() / 1000) })
    }, [])

    function getTimestamp(date: string) {
        const selectedDate = new Date(date);
        const timestamp = selectedDate.getTime();
        const timestampInSeconds = Math.floor(timestamp / 1000);
        return timestampInSeconds
    }

    function validateDates() {
        const errorMsgs: any = {};
        if (fromDate && toDate) {
            if (fromDate > toDate) {
                errorMsgs.from_date = "Should not be greater than To date.";
                errorMsgs.to_date = "Should not be less than From date.";
            }
            setErrors(errorMsgs)
        }
        if (!fromDate) {
            errorMsgs.from_date = "From date is required.";
        }
        if (!toDate) {
            errorMsgs.to_date = "To date is required.";
        }

        setErrors(errorMsgs)
        return Object.keys(errorMsgs).length
    };


    const handleSubmit = () => {
        if (!validateDates()) {
            setDates({ from_date: getTimestamp(fromDate), to_date: getTimestamp(toDate) });
        }
    }

    const handleAbsoluteButtonClick = (buttonDetail: any) => {
        const currentTimestamp = Math.floor(new Date().getTime() / 1000);
        let fromTimestamp;

        if (buttonDetail.mins) {
            fromTimestamp = currentTimestamp - (buttonDetail.mins * 60);
        }
        else {
            const currentDate = new Date()
            switch (buttonDetail.text) {
                case "1month":
                    currentDate.setMonth(currentDate.getMonth() - 1);
                    break;
                case "3months":
                    currentDate.setMonth(currentDate.getMonth() - 3);
                    break;
                case "6months":
                    currentDate.setMonth(currentDate.getMonth() - 6);
                    break;
                case "1year":
                    currentDate.setFullYear(currentDate.getFullYear() - 1);
                    break;
            }
            fromTimestamp = Math.floor(currentDate.getTime() / 1000);
        }

        setDates({ from_date: fromTimestamp, to_date: currentTimestamp });
        setSelectedAbsoluteButton(buttonDetail.id)
    }

    return (
        <div>
            <Tabs
                defaultActiveKey="absolute"
                id="uncontrolled-tab-example"
                className="mb-3"
            >
                <Tab eventKey="absolute" title="Absolute">
                    <div className="px-4">
                        <ButtonGroup className="d-flex flex-wrap text-nowrap gap-1">
                            {absoluteButtons.map((button: any) => {
                                return <Button
                                    className="flex-grow-0"
                                    variant={button.id === selectedAbsoluteButton ? "secondary" : "light"}
                                    key={button.id}
                                    onClick={() => handleAbsoluteButtonClick(button)}
                                >
                                    {button.text}
                                </Button>
                            })}
                        </ButtonGroup>
                    </div>
                </Tab>

                <Tab eventKey="relative" title="Relative">
                    <div className="serch-bar row px-4">
                        <div className="col-md-5 col-sm-12 d-flex align-items-baseline">
                            <Form.Label className="fs-5" style={{ minWidth: 55 }}>From</Form.Label>
                            <div className="w-100">
                                <Form.Control
                                    type="datetime-local"
                                    name="from_date"
                                    id="from_date"
                                    value={fromDate}
                                    onChange={(e) => { setFromDate(e.target.value); setErrors({}) }}
                                    isInvalid={errors.from_date}
                                />
                                <Form.Control.Feedback type="invalid">{errors.from_date}</Form.Control.Feedback>
                            </div>
                        </div>
                        <div className="col-md-5 col-sm-12 d-flex align-items-baseline">
                            <Form.Label className="fs-5" style={{ minWidth: 55 }}>To</Form.Label>
                            <div className="w-100">
                                <Form.Control
                                    type="datetime-local"
                                    name="to_date"
                                    id="to_date"
                                    value={toDate}
                                    onChange={(e) => { setToDate(e.target.value); setErrors({}) }}
                                    isInvalid={errors.to_date}
                                />
                                <Form.Control.Feedback type="invalid">{errors.to_date}</Form.Control.Feedback>
                            </div>
                        </div>
                        <div className="col-md-2 d-flex align-items-baseline">
                            <Button variant="primary" size="sm" onClick={handleSubmit} className="mt-2 mt-md-0 ms-auto ms-md-0">
                                Get Chart
                            </Button>
                        </div>
                    </div>
                </Tab>
            </Tabs>
        </div>
    )
}
