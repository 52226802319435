import { configureStore } from '@reduxjs/toolkit';

import rootReducer from '../reducers';

const setup = (): any => {
  return configureStore({ reducer: rootReducer });
};
const store = setup();

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;

export const StoreService = {
  setup,
};