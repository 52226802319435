import { useEffect, useState } from "react";
import DateRangePicker from "./date-range-picker";
import ChartCard from "./chart-card";
import { Tab, Tabs } from "react-bootstrap";
import jsonData from "./apis-key.json";
import InputSelect from "../common/input-select";
import { GraphService } from "../store/services";

export default function ChartCardList(props: any) {
    const [dates, setDates] = useState({});
    const [devices, setDevices] = useState([]);
    const [selectedDevice, setSelectedDevice] = useState("")

    useEffect(() => {
        GraphService.getDevices()
            .then((res) => {
                if (res && res.data) {
                    const deviceList = res.data.devices;
                    setDevices(deviceList);
                    if (deviceList.length) {
                        setSelectedDevice(res.data.devices[0].device_id)
                    }
                }
            })
            .catch((error) => console.log('error', error))
    }, []);

    return (
        <div className="p-4">
            <div className="d-flex mb-3">
                <InputSelect
                    className="d-flex align-items-baseline text-nowrap gap-3 fs-5"
                    labelName="Device"
                    options={devices.map((device: any) => ({ label: device.hostname, value: device.device_id }))}
                    value={selectedDevice}
                    onChange={(e: any) => setSelectedDevice(e.target.value)}
                />
            </div>
            <DateRangePicker setDates={setDates} />
            <div className="mt-4">
                <div>
                    {selectedDevice ? <Tabs
                        defaultActiveKey="graphs"
                        id="uncontrolled-tab-example"
                        className="mb-3"
                    >
                        {jsonData.map((tab: any) => {
                            return <Tab eventKey={tab.name} title={tab.title} key={tab.name}>
                                <div className="d-flex gap-3 flex-wrap">
                                    {tab.graph_list.map((graphDetail: any) => {
                                        return <ChartCard dates={dates} graphDetail={graphDetail} key={graphDetail.key} deviceId={selectedDevice} />
                                    })}
                                </div>
                            </Tab>
                        })}
                    </Tabs> :
                        <p className="text-muted">No Found..!!</p>
                    }
                </div>
            </div>
        </div>
    )
}
