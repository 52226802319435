import { Form } from "react-bootstrap";

export default function InputSelect(props: any) {
    const { className, labelName, options, onChange, value } = props;

    return (
        <Form.Group className={className}>
            <Form.Label>{labelName}</Form.Label>
            <Form.Select
                onChange={onChange}
                value={value}
            >
                {options.map((option: { value: string, label: string }) => {
                    return <option value={option.value} key={option.value}>{option.label}</option>
                })}
            </Form.Select>
        </Form.Group>
    );
}
